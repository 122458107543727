import { fillEmptyDatesWithAstrick, allDates, leapYearDates } from '../allDates';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

export const useStyles = makeStyles({
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: 'red',
    },
  },
});

const allMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getMonthlyDataforXLS = (arr, idRequired) => {
  const newArr = [];
  allMonths.forEach((date, i) => {
    const count = arr[i];
    if (idRequired) {
      newArr.push({
        id: i + 1,
        date,
        count: count === '*' ? 'No Data' : count,
      });
    } else {
      newArr.push({
        date,
        count: count === '*' ? 'No Data' : count,
      });
    }
  })
  return newArr;
}

export const getXLSData = (arr, idRequired, isDucs) => {
  const newArr = [];
  let arrToUse = allDates;
  if (isDucs) {
    arrToUse = leapYearDates
  }
  arrToUse.forEach((date, i) => {
    const count = arr[i];
    if (idRequired) {
      newArr.push({
        id: i + 1,
        date,
        count: count === '*' ? 'No Data' : count,
      });
    } else {
      newArr.push({
        date,
        count: count === '*' ? 'No Data' : count,
      });
    }
  });
  return newArr;
};

export const columnSchema = [
  {
    field: 'id',
    headerName: 'row',
    width: 20,
    hide: true,
  },
  { field: 'date', headerName: 'Date', width: 120 },
  { field: 'count', headerName: 'Count', width: 100 },
];

const fillEmptyMonthsWithAsterisk = (dates, labels) => {
  const finalArr = [];

  allMonths.forEach((month) => {
    const indexOf = labels.indexOf(month);
    if (indexOf === -1) {
      finalArr.push('*');
    } else {
      finalArr.push(dates[indexOf]);
    }
  });
  return finalArr;
};

export const getBarData = (dates, clickedRegion) => {
  const dates2020 = [];
  const labels2020 = [];

  const dates2021 = [];
  const labels2021 = [];

  const dates2022 = [];
  const labels2022 = [];

  const dates2023 = [];
  const labels2023 = [];

  const { Gulf, Midwest, Northeast, West } = clickedRegion;
  let northEastVisible =
    Northeast || (!Northeast && !Gulf && !Midwest && !West);

  dates.forEach((date) => {
    if (date.month.slice(0, 4) === '2020') {
      if (northEastVisible) {
        return;
      } else {
        dates2020.push(date['']);
        labels2020.push(moment(date.month.slice(0, 10)).format('MMM'));
      }

    }

    if (date.month.slice(0, 4) === '2021') {
      const newDate = moment(date.month.slice(0, 10)).format('MMM');

      if (northEastVisible) {
        if (moment(date.month.slice(0, 10)).isAfter('2021-03-30')) {
          dates2021.push(date['']);
          labels2021.push(newDate);
        }
      } else {
        dates2021.push(date['']);
        labels2021.push(newDate);
      }
    }

    if (date.month.slice(0, 4) === '2022') {
      dates2022.push(date['']);
      labels2022.push(moment(date.month.slice(0, 10)).format('MMM'));
    }
    if (date.month.slice(0, 4) === '2023') {
      dates2023.push(date['']);
      labels2023.push(moment(date.month.slice(0, 10)).format('MMM'));
    }
  });

  const values2020 = fillEmptyMonthsWithAsterisk(dates2020, labels2020);
  const values2021 = fillEmptyMonthsWithAsterisk(dates2021, labels2021);
  const values2022 = fillEmptyMonthsWithAsterisk(dates2022, labels2022);
  const values2023 = fillEmptyMonthsWithAsterisk(dates2023, labels2023);

  return {
    values2020,
    values2021,
    values2022,
    values2023
  };
};

export const getLineChartData = (
  allData,
  clickedRegion,
  type
) => {
  const { Gulf, Midwest, Northeast, West } = clickedRegion;

  const dates2023 = [];
  const labels2023 = [];

  const dates2022 = [];
  const labels2022 = [];

  const dates2021 = [];
  const labels2021 = [];

  const dates2020 = [];
  const labels2020 = [];

  const dates2019 = [];
  const labels2019 = [];

  const dates2018 = [];
  const labels2018 = [];

  const dates2017 = [];
  const labels2017 = [];

  const dates2016 = [];
  const labels2016 = [];

  let northEastVisible =
    Northeast || (!Northeast && !Gulf && !Midwest && !West);
  const isDucs = type === 'ducs'
  if (isDucs) {
    allData.forEach((item) => {
      if (item.date.slice(0, 4) === '2023') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2023.push(newDate);
        dates2023.push(item.count);
      }
      if (item.date.slice(0, 4) === '2022') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2022.push(newDate);
        dates2022.push(item.count);
      }
      if (item.date.slice(0, 4) === '2021') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2021.push(newDate);
        dates2021.push(item.count);
      }
      if (item.date.slice(0, 4) === '2020') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2020.push(newDate);
        dates2020.push(item.count);
      }

      if (item.date.slice(0, 4) === '2019') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2019.push(newDate);
        dates2019.push(item.count);
      }

      if (item.date.slice(0, 4) === '2018') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2018.push(newDate);
        dates2018.push(item.count);
      }

      if (item.date.slice(0, 4) === '2017') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2017.push(newDate);
        dates2017.push(item.count);
      }

      if (item.date.slice(0, 4) === '2016') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2016.push(newDate);
        dates2016.push(item.count);
      }
    });
  } else {
    allData.forEach((item) => {
      if (item.date.slice(0, 4) === '2021') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');

        if (northEastVisible) {
          if (moment(item.date.slice(0, 10)).isAfter('2021-03-30')) {
            dates2021.push(item['']);
            labels2021.push(newDate);
          }
        } else {
          dates2021.push(item['']);
          labels2021.push(newDate);
        }
      }
      if (item.date.slice(0, 4) === '2022') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2022.push(newDate);
        dates2022.push(item['']);
      }

      if (item.date.slice(0, 4) === '2023') {
        const newDate = moment(item.date.slice(0, 10)).format('DD MMM');
        labels2023.push(newDate);
        dates2023.push(item['']);
      }
    });
  }

  const newDates2023 = fillEmptyDatesWithAstrick(
    dates2023,
    labels2023,
    2023,
    northEastVisible,
    isDucs
  );

  const newDates2022 = fillEmptyDatesWithAstrick(
    dates2022,
    labels2022,
    2022,
    northEastVisible,
    isDucs
  );
  const newDates2021 = fillEmptyDatesWithAstrick(
    dates2021,
    labels2021,
    2021,
    northEastVisible,
    isDucs
  );

  const newDates2020 = fillEmptyDatesWithAstrick(
    dates2020,
    labels2020,
    2020,
    northEastVisible,
    isDucs
  );
  const newDates2019 = fillEmptyDatesWithAstrick(
    dates2019,
    labels2019,
    2019,
    northEastVisible,
    isDucs
  );

  const newDates2018 = fillEmptyDatesWithAstrick(
    dates2018,
    labels2018,
    2018,
    northEastVisible,
    isDucs
  );
  const newDates2017 = fillEmptyDatesWithAstrick(
    dates2017,
    labels2017,
    2017,
    northEastVisible,
    isDucs
  );
  const newDates2016 = fillEmptyDatesWithAstrick(
    dates2016,
    labels2016,
    2016,
    northEastVisible,
    isDucs
  );

  return {
    newDates2023,
    newDates2022,
    newDates2021,
    newDates2020,
    newDates2019,
    newDates2018,
    newDates2017,
    newDates2016,
  };
};


export const getFlagDataForSubRegion = (subregionName) => {
  const name = subregionName.toLowerCase()

  if (name === 'colorado wo sj') {
    return 'co';
  }
  if (name === 'newmexico') {
    return 'nm';
  }
  if (name === 'wyoming') {
    return 'wy';
  }
  if (name === 'north dakota') {
    return 'nd';
  }
  return name.substr(-2, 2)
}

function daysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

function loopThroughDatesAndFillData(startYear, endYear, dataWithPoints) {
  const datesWithPoints = {};

  for (let year = startYear; year <= endYear; year++) {
    const valuesForYear = [];

    for (let month = 0; month < 12; month++) {
      for (let day = 1; day <= daysInMonth(month, year); day++) {
        const dateKey = moment(new Date(year, month, day)).format("DD-MM-YYYY");
        if (dataWithPoints[dateKey] !== undefined) {
          valuesForYear.push(dataWithPoints[dateKey]);
        } else {
          valuesForYear.push("*");
        }
      }
    }
    datesWithPoints[year] = valuesForYear;
  }
  return datesWithPoints;
}

export function getSeasonalTimeseriesColor(year) {
  const lastDigit = parseInt(year.slice(-1));
  const colorMap = {
    0: "rgb(20,255,217)",
    1: "rgb(235,127,82)",
    2: "rgb(104,162,211)",
    3: "rgb(90,186,96)",
    4: "rgb(128,0,128)",
    5: "rgb(51,255,87)",
    6: "rgb(0,0,0)",
    7: "rgb(255,255,0)",
    8: "rgb(241,81,82)",
    9: "rgb(36,40,98)",
  };

  return colorMap[lastDigit] || "rgb(0,0,0)"; // Default to black if not found
}

export const getLineChartDataPointsSeasonal = (allData, isDucs) => {
  const convertedData = {};
  const startYear = allData?.[0].date.slice(0, 4);
  const endYear = allData?.[allData.length - 1].date.slice(0, 4);
  allData?.forEach((item) => {
    const date = moment(item.date).format("DD-MM-YYYY");
    convertedData[date] = isDucs ? item["count"] : item[""];
  });
  const pointsWithDateKey = loopThroughDatesAndFillData(
    startYear,
    endYear,
    convertedData
  );
  // Organize data by years
  return pointsWithDateKey;
};

export const getSeasonalChartPointsData = (points, year) => {
  const arrToReturn = points;
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  if (!isLeapYear(parseInt(year))) {
    arrToReturn.splice(59, 0, "*");
  }
  return arrToReturn;
};

export const getBarDataSeasonal = (dates) => {
  const barData = {};
  const startYear = parseInt(dates?.[0].month.slice(0, 4));
  const endYear = parseInt(dates?.[dates.length - 1].month.slice(0, 4));

  // Iterate over the years dynamically
  for (let year = startYear; year <= endYear; year++) {
    const datesArray = [];
    const labelsArray = [];
    dates.forEach((date) => {
      const dateYear = parseInt(date.month.slice(0, 4));
      if (dateYear === year) {
        const newDate = moment(date.month.slice(0, 10)).format('MMM');
        datesArray.push(date['']);
        labelsArray.push(newDate);
      }
    });
    const valuesArray = fillEmptyMonthsWithAsterisk(datesArray, labelsArray);
    barData[year] = valuesArray;
  }
  return barData;
};
