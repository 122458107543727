import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Select, MenuItem, Snackbar, Dialog } from '@material-ui/core';
import { TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import XLSX from 'xlsx';

import { db } from '../../App';
import './admin.scss';
import axios from 'axios';

const DataTable = ({ userData, token }) => {
  const [allProfiles, setAllProfiles] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [roleChanged, setRoleChanged] = useState(false);
  const [showDialog, setShowDialog] = useState({
    show: false,
    role: '',
    userId: '',
  });
  const [noOfDays, setNoOfDays] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false)

  const changeRole = async (role, userId) => {
    const userRef = doc(db, 'users', userId);
    if (role === 'temporary_hyperion_user' || role === 'temporary_theia_user') {
      setShowDialog({
        show: true,
        role,
        userId,
      });
    } else {
      await updateDoc(userRef, {
        role,
      });
      setRoleChanged(!roleChanged);
      setIsSnackBarOpen(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'Email', flex: 2 },
    { field: 'firstName', headerName: 'First Name',  flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'creation', headerName: 'Registration Date', flex: 2,
      renderCell: (params, val) => {
        return params.row.creation.substr(5)
      }
  },
    { field: 'lastLoggedIn', headerName: 'Last Logged In', minWidth: 150, flex: 2,
      renderCell: (params, val) => {
        return params.row.lastLoggedIn.substr(5)
      }
  },
    {
      field: 'role',
      headerName: 'role',
      minWidth: 150,
      flex:3,
      renderCell: (params, val) => {
        const { userId, role } = params.row;
        switch (userData.role) {
          case 'super_user':
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  labelId="criteria-label"
                  id="criteria"
                  defaultValue={role}
                  label="Age"
                  onChange={(e) => changeRole(e.target.value, userId)}
                >
                  <MenuItem value="new_user">New User</MenuItem>
                  <MenuItem value="ukraine_monitor_customer">
                    Ukraine Monitor Customer
                  </MenuItem>
                  <MenuItem value="temporary_hyperion_user">
                    Temporary Hyperion User
                  </MenuItem>
                  <MenuItem value="temporary_theia_user">
                    Temporary Theia User
                  </MenuItem>
                  <MenuItem value="theia_customer">Theia Customer</MenuItem>
                  <MenuItem value="hyperion_customer">
                    Hyperion Customer
                  </MenuItem>
                  <MenuItem value="co-worker">Co Worker</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                {role === 'temporary_hyperion_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
                {role === 'temporary_theia_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
              </div>
            );
          case 'admin':
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  labelId="criteria-label"
                  id="criteria"
                  defaultValue={role}
                  label="Age"
                  onChange={(e) => changeRole(e.target.value, userId)}
                  disabled={role === 'admin' || role === 'super_user'}
                >
                  <MenuItem value="new_user">New User</MenuItem>
                  <MenuItem value="ukraine_monitor_customer">
                    Ukraine Monitor Customer
                  </MenuItem>
                  <MenuItem value="theia_customer">Theia Customer</MenuItem>
                  <MenuItem value="hyperion_customer">
                    Hyperion Customer
                  </MenuItem>
                  <MenuItem value="co-worker">Co Worker</MenuItem>
                  <MenuItem value="temporary_theia_user">
                    Temporary Theia User
                  </MenuItem>
                  <MenuItem value="temporary_hyperion_user">
                    Temporary Hyperion User
                  </MenuItem>
                  {role === 'admin' && <MenuItem value="admin">Admin</MenuItem>}
                  {role === 'super_user' && (
                    <MenuItem value="super_user">Super User</MenuItem>
                  )}
                </Select>
                {role === 'temporary_hyperion_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
                 {role === 'temporary_theia_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
              </div>
            );
          default:
            return params.value;
        }
      },
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, [roleChanged]);

  const getAllUsers = async () => {
    setIsDataLoading(true)
    const querySnapshot = await getDocs(collection(db, 'users'));
    const authUsers = await axios.get('/users/all', {
      headers: { Authorization: 'Bearer ' + token },
    });
    let allAuthUsers = {}
    if(authUsers.data) {
      allAuthUsers = authUsers.data.authUsers
    }
    let profiles = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const allData = doc.data();
      const { email, role } = allData;
      let expiry_date = '';
      let firstName = '-';
      let lastName = '-'

      if (role === 'temporary_hyperion_user' || role === 'temporary_theia_user') {
        expiry_date = allData.expiry_date.seconds;
      }

      if(allData.firstName) {
        firstName = allData.firstName
      }

      if(allData.lastName) {
        lastName = allData.lastName
      }
      if (email !== userData.email) {
        profiles.push({
          id: email,
          role,
          userId: doc.id,
          expiry_date: expiry_date ? moment.unix(expiry_date).format('DD MMM YYYY') : '-',
          firstName,
          lastName,
          creation: allAuthUsers[email] ? allAuthUsers[email].creationTime : '',
          lastLoggedIn: allAuthUsers[email] ? allAuthUsers[email].lastSignInTime : '',
        });
      }
    });
    console.log(profiles, 'profiles')
    setAllProfiles(profiles);
    setIsDataLoading(false)
  };

  const giveTemporaryAccess = async () => {
    const { role, userId } = showDialog;
    const userRef = doc(db, 'users', userId);

    const expiry_date = moment(Date.now()).add('days', noOfDays)._d;

    await updateDoc(userRef, {
      role,
      expiry_date,
    });
    setRoleChanged(!roleChanged);
    setShowDialog({ show: false });
    setIsSnackBarOpen(true);
  };

  const downloadXLS = () => {
    const users = allProfiles.map(
      ({ id, role, expiry_date, firstName, lastName, lastLoggedIn, creation }) => ({
        email: id,
        role,
        'expiry date': expiry_date,
        'First Name': firstName,
        'Last Name': lastName,
        'Created On': creation,
        'Last Logged In': lastLoggedIn
      })
    );
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Users');
    XLSX.writeFile(wb, `allUsers.xlsx`);
  }

  return (
    <div className="admin">
      <Dialog
        open={showDialog.show}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            return;
          } else {
            setShowDialog({ show: false });
          }
        }}
        classes={{ root: 'MuiDialog-root pickDays' }}
        disableEscapeKeyDown
      >
        <div className="admin__dialog">
          <div className="dialogHeading">
            Enter the number of days, this user should have access for:
          </div>
          <TextField
            placeholder="No Of Days"
            type="number"
            value={noOfDays}
            onChange={(e) => setNoOfDays(e.target.value)}
          />
          <Button
            style={{ display: 'block', marginTop: 10 }}
            size="small"
            variant="contained"
            onClick={giveTemporaryAccess}
          >
            Give Access
          </Button>
        </div>
      </Dialog>
      <div className="admin__navbar">
        <div className="admin__navbarText">Admin</div>
        <div className="admin__navbarText">
          <Link to="/" style={{ color: '#000' }}>
            Hyperion
          </Link>
        </div>
      </div>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={2000}
        onClose={() => setIsSnackBarOpen(false)}
        message="Role Updated"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <div className="admin__content">
        <div className="admin__currentUser">
          Logged in as: <strong>{userData.email}</strong>
        </div>
        <div className="admin__role">
          <strong>({userData.role})</strong>
        </div>

        <div className="admin__download" onClick={downloadXLS}>
          <div className="admin__download--icon">
          <DownloadIcon style={{ color: '#333', fontSize: "22px" }} />
          </div>
         <div className="admin__download--text">
          Download XLS
         </div>
          
        </div>
        <div className="admin__table">
          <DataGrid
            rows={allProfiles}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}
            loading={isDataLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
